import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Spinner from 'components/spinner';
import useStores from 'hooks/use-stores';
import './style.scss';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: '#DDDEE4',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#A9B7C4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    []
  );

  return options;
};

type StripeCheckoutFormProps = {
  amount: string;
  type: string;
};
const StripeCheckoutForm = ({ amount, type }: StripeCheckoutFormProps) => {
  const { authStore, paymentStore } = useStores();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setSubmitting(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.

      setSubmitting(false);
      return;
    }

    try {
      const cardNumber = elements.getElement(CardNumberElement);
      const result = await stripe.createToken(cardNumber);
      if (result.error) {
        throw new Error(result.error.message);
      } else {
        await paymentStore.pay({
          token: result.token.id,
          type,
          amount,
        });

        authStore.getBalance();
        toast.success('Payment Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // clear form
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
      }
    } catch (err) {
      toast.error(err.response?.data?.message || err.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardNumberElement
        options={{ ...options, placeholder: 'Card Number', disabled: isSubmitting }}
      />
      <CardExpiryElement options={{ ...options, disabled: isSubmitting }} />
      <CardCvcElement options={{ ...options, disabled: isSubmitting }} />
      <div className="mt-6 max-w-[500px] flex justify-end">
        <button
          type="submit"
          disabled={!stripe || isSubmitting}
          className="relative rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-2 px-8 text-lg font-extrabold text-dark"
        >
          pay now
          <span className="absolute top-1">
            <Spinner loading={isSubmitting} />
          </span>
        </button>
      </div>
    </form>
  );
};

export default observer(StripeCheckoutForm);
