/* eslint-disable react/button-has-type */
/* eslint-disable global-require */
import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useStores from 'hooks/use-stores';
import useCurrentUser from 'hooks/use-current-user';
import Layout from 'containers/layout';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';
// import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
const userImage = require('assets/images/user-placeholder.png');

const Profile = () => {
  const { authStore, artStore } = useStores();
  const currentUser = useCurrentUser(authStore);
  const [selectedImage, setSelectedImage] = useState(null);
  const [edit, setEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSaveProfile = () => {
    setEdit(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: currentUser?.given_name || '',
      lastName: currentUser?.family_name || '',
      email: currentUser?.email || '',
      password: '',
    },
    validationSchema: yup.object({
      firstName: yup.string().required('First Name is required'),
      lastName: yup.string().required('Last Name is required'),
      email: yup.string().email('Enter a valid email').required('Email is required'),
      password: yup
        .string()
        .min(8, 'Password should be of minimum 8 characters length')
        .test('contain-lowercase', 'Password must contain lowercase characters', (value) =>
          /[a-z]/g.test(value)
        )
        .test('contain-uppercase', 'Password must have uppercase characters', (value) =>
          /[A-Z]/g.test(value)
        )
        .test('contain-symbol', 'Password must have symbol characters', (value) =>
          /\W/g.test(value)
        )
        .required('Password is required'),
    }),
    onSubmit: handleSaveProfile,
  });

  const renderLikedArt = () => {
    return (
      <section className="pb-16">
        <h3 className="text-2xl font-medium">Liked Art:</h3>
        <div className="mt-8">
          <div className="w-[13rem] rounded-lg border-[0.5px] border-solid border-mischka py-4 px-6">
            <div className="mb-3 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src="https://i.pravatar.cc/150?img=3"
                  alt="avatar"
                  className="mr-2 h-5 w-5 rounded-full"
                />
                <div>
                  <p className="text-xs font-semibold">Ezra Stevenson</p>
                  <div className="flex items-center text-tiny text-lightGray">
                    <ClockIcon className="mr-1 inline-block h-2 w-2" />
                    <span>2 hours ago</span>
                  </div>
                </div>
              </div>
              <HeartIcon className="h-5" />
            </div>
            <img
              className="h-[9rem] w-full rounded-lg object-cover"
              src={require('../assets/images/image-2.png')}
              alt="img-1"
            />
            <div className="flex items-center justify-between pt-2">
              <div>
                <p className="font-bold">Cosmic Flower</p>
                <div className="text-xs">
                  <p>Hyperion Blend</p>
                  <p>Square 1:1</p>
                </div>
              </div>
            </div>
            <div className="mt-1 flex justify-end">
              <button className="text-xs text-link">See Details</button>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderProfileEdit = () => {
    return (
      <form
        className="mb-12 flex flex-col items-center md:items-start"
        onSubmit={formik.handleSubmit}
      >
        <div>
          <div className="md:w-[20rem]">
            <label htmlFor="firstName" className="font-semibold text-xl">
              First Name
              <input
                id="firstName"
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange('firstName')}
                disabled={formik.isSubmitting}
              />
            </label>
            <div className="h-5 text-left">
              <span className="text-red text-xs">
                {formik.touched.firstName && formik.errors.firstName}
              </span>
            </div>
          </div>
          <div className="md:w-[20rem] mt-1 md:mt-3">
            <label htmlFor="lastName" className="font-semibold text-xl">
              Last Name
              <input
                id="lastName"
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange('lastName')}
                disabled={formik.isSubmitting}
              />
            </label>
            <div className="h-5 text-left">
              <span className="text-red text-xs">
                {formik.touched.lastName && formik.errors.lastName}
              </span>
            </div>
          </div>
          <div className="md:w-[20rem] mt-1 md:mt-3">
            <label htmlFor="email" className="font-semibold text-xl">
              E-Mail
              <input
                id="email"
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="E-Mail"
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                disabled={formik.isSubmitting}
              />
            </label>
            <div className="h-5 text-left">
              <span className="text-red text-xs">
                {formik.touched.email && formik.errors.email}
              </span>
            </div>
          </div>
          <div className="mt-3">
            <div className="relative">
              <label htmlFor="password" className="font-semibold text-xl">
                Password
                <input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange('password')}
                  disabled={formik.isSubmitting}
                  className="block w-full rounded-md px-6 py-3 bg-dark text-lg placeholder-lightGray shadow-md focus:placeholder-gray-500 focus:outline-none"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                  <svg
                    onClick={() => setShowPassword(false)}
                    className={`h-6 text-gray-700 ${showPassword ? 'block' : 'hidden'}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                    />
                  </svg>
                  <svg
                    onClick={() => setShowPassword(true)}
                    className={`h-6 text-gray-700 ${showPassword ? 'hidden' : 'block'}`}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                    />
                  </svg>
                </div>
              </label>
            </div>
            <div className="h-5 text-left">
              <span className="text-red text-xs">
                {formik.touched.password && formik.errors.password}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-1 items-center justify-center">
          <button
            className="mt-10 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-14 text-lg font-bold text-dark"
            type="submit"
          >
            save
            {formik.isSubmitting && (
              <span className="ml-2 absolute top-[0.6rem]">
                <svg
                  className="motion-reduce:hidden animate-spin h-4 w-4 text-dark"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              </span>
            )}
          </button>
        </div>
      </form>
    );
  };

  const renderProfileAvatarEdit = () => {
    return (
      <div className="flex flex-col items-center md:items-start">
        <div className="flex">
          <img
            alt="avatar"
            className="h-36 w-36 rounded-full"
            src={selectedImage ? URL.createObjectURL(selectedImage) : userImage}
          />
          {/* <button type="file" id="myFile" name="filename">
            <EditIcon />
          </button> */}
        </div>
        <span className="mt-8 text-2xl">{`${currentUser?.given_name || ''} ${
          currentUser?.family_name || ''
        }`}</span>
        <input
          accept="image/*"
          id="avatar-input"
          type="file"
          name="avatar"
          onChange={(event) => {
            setSelectedImage(event.target.files[0]);
          }}
          className="hidden select-none"
        />
      </div>
    );
  };

  const renderProfileInfo = () => {
    return (
      <div className="profile">
        <div className="flex flex-col items-center md:flex-row">
          <img
            src={userImage}
            alt="avatar"
            className="h-[5.5rem] w-[5.5rem] rounded-full md:h-[10rem] md:w-[10rem]"
          />
          <div className="mt-8 flex flex-col md:mt-0 md:ml-4">
            <span className="text-2xl">{`${currentUser?.given_name || ''} ${
              currentUser?.family_name || ''
            }`}</span>
            <span className="text-lg">Account ID: {authStore.cognitoid || ''}</span>
            <span className="text-lg">Creations: {artStore.jobs.length}</span>
            <span className="text-lg text-denim">Credits: {authStore.balance || 0}</span>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-[10rem_10rem] justify-center gap-x-5 md:justify-start">
          {/* <button
            className="w-full rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-extrabold text-dark"
            onClick={() => setEdit(true)}
          >
            edit profile
          </button> */}
          <button className="w-full rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-extrabold text-dark">
            logout
          </button>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <div className="absolute h-[27rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:h-[25rem] md:bg-headerBg md:bg-contain md:bg-right" />
      <div className="md:m-auto md:max-w-[90rem]">
        <div className="relative z-[10] px-8">
          <section className="pb-14 pt-40 md:h-[40rem] md:pt-56 md:pb-20">
            {edit ? renderProfileAvatarEdit() : renderProfileInfo()}
          </section>
          {/* {edit ? renderProfileEdit() : renderLikedArt()} */}
        </div>
      </div>
    </Layout>
  );
};

export default observer(Profile);
