/* eslint-disable react/button-has-type */
import { Link } from 'react-router-dom';

import { ReactComponent as DiscordIcon } from '../assets/icons/discord.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg';
import { ReactComponent as MailIcon } from '../assets/icons/mail.svg';
import { ReactComponent as LogoTextIcon } from '../assets/icons/logo-text.svg';
// import { ReactComponent as GooglePayIcon } from '../assets/icons/google-pay.svg';
// import { ReactComponent as PaypalIcon } from '../assets/icons/paypal.svg';
import { ReactComponent as StripeIcon } from '../assets/icons/stripe.svg';

const Footer = () => {
  return (
    <footer className="bg-mischka py-5 text-center md:pt-10 md:px-10">
      <div className="justify-between md:m-auto md:flex md:max-w-7xl">
        <div className="flex flex-col items-center">
          <LogoTextIcon className="h-16 text-dark" />
          <div className="flex justify-center">
            <a target="_blank" href="https://discord.gg/3eqCqN493J" rel="noreferrer">
              <DiscordIcon className="h-8 fill-dark" />
            </a>
            <a
              className="mx-12"
              target="_blank"
              href="https://twitter.com/neuralblender"
              rel="noreferrer"
            >
              <TwitterIcon className="h-8 fill-dark" />
            </a>
            <a href="mailto:info@neuralblender.com">
              <MailIcon className="h-8 fill-dark" />
            </a>
          </div>
        </div>
        <div className="hidden grid-cols-2 gap-x-20 md:grid">
          <Link to="/create-art" className="text-2xl font-extrabold text-dark">
            Create
          </Link>
          <Link to="/store" className="text-2xl font-extrabold text-dark">
            Store
          </Link>
          <Link to="/my-art" className="text-2xl font-extrabold text-dark">
            My Art
          </Link>
          <Link to="/faq" className="text-2xl font-extrabold text-dark">
            FAQ
          </Link>
          <Link to="/queue" className="text-2xl font-extrabold text-dark">
            Queue
          </Link>
        </div>
        <div className="hidden items-start md:flex">
          {/* <button>
            <GooglePayIcon />
          </button>
          <button className="mx-5">
            <PaypalIcon />
          </button> */}
          <Link to="/store">
            <StripeIcon />
          </Link>
        </div>
      </div>
      <div className="mt-6 md:mt-20">
        <p className="text-center text-xs text-dark md:text-lg md:font-bold">
          Copyright 2022 Neural Blender
        </p>
      </div>
    </footer>
  );
};

export default Footer;
