/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useRef } from 'react';
import { useImmer } from 'use-immer';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Stepper from 'react-stepper-primitive';
import { Range } from 'react-range';
import { toast } from 'react-toastify';

import Spinner from 'components/spinner';
import Layout from 'containers/layout';
import useStores from 'hooks/use-stores';
// import { ReactComponent as InfoIcon } from '../assets/icons/info.svg';

const CreatArt = () => {
  const {
    authStore: { balance, getBalance },
    artStore: { createArt },
  } = useStores();
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);

  const [state, updateState] = useImmer({
    text: '',
    resolution: '256',
    aspectRatio: '1:1',
    tier: 'free',
    ai: 'vqganclip',
    diffusionQuality: 'low',
    creditsRequired: 0,
    iterations: [200],
    width: 256,
    height: 256,
    superRes: false,
    multiple: 1,
    lowResDetail: 'Low (256x256)',
    highResDetail: 'High (512x512)',
  });

  useEffect(() => {
    let lowResDetail = 'Low (256x256)';
    let highResDetail = 'High (512x512)';

    if (state.aspectRatio === '16:9') {
      lowResDetail = 'Low (342x192)';
      highResDetail = 'High (684x384)';
    } else if (state.aspectRatio === '9:16') {
      lowResDetail = 'Low (192x342)';
      highResDetail = 'High (384x684)';
    }

    if (state.superRes) {
      if (state.aspectRatio === '1:1') {
        lowResDetail = 'Low (1024x1024)';
        highResDetail = 'High (2048x2048)';
      } else if (state.aspectRatio === '16:9') {
        lowResDetail = 'Low (1368x760)';
        highResDetail = 'High (2736x1536)';
      } else if (state.aspectRatio === '9:16') {
        lowResDetail = 'Low (760x1368)';
        highResDetail = 'High (1536x2736)';
      }
    }

    updateState((draft) => {
      draft.lowResDetail = lowResDetail;
      draft.highResDetail = highResDetail;
    });
  }, [state.aspectRatio, state.superRes]);

  useEffect(() => {
    const calculateCredits = () => {
      // eslint-disable-next-line prefer-destructuring
      let tier = state.tier;
      let required = 0;

      if (state.multiple > 1) {
        tier = 'premium';
        updateState((draft) => {
          draft.tier = tier;
        });
      }

      if (state.ai === 'diffclip') {
        if (tier === 'premium') {
          required = 5;
        }

        if (state.diffusionQuality === 'medium') {
          required = 10;
        } else if (state.diffusionQuality === 'high') {
          required = 25;
        } else if (state.diffusionQuality === 'max') {
          required = 50;
        }

        if (state.resolution === '512') {
          if (required === 0) {
            required = 5;
          }
          required *= 2;
        }
      } else if (state.ai === 'vqganclip') {
        if (tier === 'premium') {
          required = 2;
        }
        if (state.resolution === '512') {
          required = 10;
        }

        if (state.iterations[0] > 200) {
          if (required === 0) {
            required = 2;
          }
          required *= state.iterations[0] / 200;
        }
      }

      if (state.superRes) {
        if (required === 0) {
          // only super res is a special case and would require 4
          required = 10;
        } else {
          required += 8;
        }
      }

      if (required > 0) {
        updateState((draft) => {
          draft.tier = 'premium';
        });
      }
      updateState((draft) => {
        draft.creditsRequired = required * state.multiple;
      });
    };

    calculateCredits();
  }, [state]);

  const handleTextChange = (e) => {
    updateState((draft) => {
      draft.text = e.target.value;
    });
  };

  const handleSuperRes = (e) => {
    updateState((draft) => {
      draft.superRes = e.target.checked;
    });
  };

  const onChangeStepper = (val) => {
    updateState((draft) => {
      draft.multiple = val;
    });
  };

  const handleChange = (e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    let aspectRatio = state.aspectRatio;
    let resolution = state.resolution;
    let ai = state.ai;
    let tier = state.tier;
    let diffusionQuality = state.diffusionQuality;
    let multiple = state.multiple;
    let superRes = state.superRes;
    let iterations = state.iterations[0];

    if (targetName === 'resolution') {
      resolution = targetValue;
    } else if (targetName === 'aspectratio') {
      aspectRatio = targetValue;
    } else if (targetName === 'tier') {
      tier = targetValue;

      // Reset setting to free tier if tier was selected
      if (tier === 'free') {
        resolution = '256';
        diffusionQuality = 'low';
        multiple = 1;
        superRes = false;
        if (iterations > 200) {
          iterations = 200;
        }
      }
    } else if (targetName === 'ai') {
      ai = targetValue;
      if (ai === 'diffclip') {
        aspectRatio = '1:1';
      }
    } else if (targetName === 'diffusionquality') {
      diffusionQuality = targetValue;
    }

    let width;
    let height;
    switch (aspectRatio) {
      case '1:1': {
        if (resolution === '256') {
          width = 256;
          height = 256;
        } else if (resolution === '512') {
          width = 512;
          height = 512;
        }
        break;
      }
      case '16:9': {
        if (resolution === '256') {
          width = 342;
          height = 192;
        } else if (resolution === '512') {
          width = 684;
          height = 384;
        }
        break;
      }
      default: {
        if (resolution === '256') {
          width = 192;
          height = 342;
        } else if (resolution === '512') {
          width = 384;
          height = 684;
        }
        break;
      }
    }

    updateState((draft) => {
      draft.aspectRatio = aspectRatio;
      draft.resolution = resolution;
      draft.iterations = [iterations];
      draft.ai = ai;
      draft.tier = tier;
      draft.multiple = multiple;
      draft.superRes = superRes;
      draft.diffusionQuality = diffusionQuality;
      draft.width = width;
      draft.height = height;
    });
  };

  const handleSlider = (values) => {
    updateState((draft) => {
      draft.iterations = values;
    });
  };

  const handleBuyCredits = () => {
    navigate('/store');
  };

  const handleBlend = async () => {
    try {
      setSubmitting(true);
      await createArt({
        ai: state.ai,
        text: state.text,
        width: state.width,
        height: state.height,
        superRes: state.superRes,
        tier: state.tier,
        multiple: state.multiple,
        aspectRatio: state.aspectRatio,
        resolution: state.resolution,
        iterations: state.iterations[0],
        diffusionQuality: state.diffusionQuality,
        creditsRequired: state.creditsRequired,
      });

      getBalance();
      navigate('/queue');
    } catch (err) {
      toast.error(err.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onChangeSuperRes = (e) => {
    updateState((draft) => {
      draft.superRes = e.target.checked;
    });
  };

  return (
    <Layout>
      <div className="absolute h-[28rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right md:h-[25rem]" />
      <div className="body relative z-50">
        <section className="px-4 pb-14 pt-40 md:h-[35rem] md:px-10 md:pb-20 md:pt-60">
          <div className="text-center md:w-1/2 md:text-left">
            <h1 className="mb-5 text-4xl font-bold">Let’s blend some art</h1>
            <p>Ask the AI anything:</p>
            <div className="mt-6 px-8 md:px-0">
              <input
                onChange={handleTextChange}
                placeholder="i.e. Dog walking on Mars"
                className="w-full rounded-lg bg-silver py-4 px-6 text-dark placeholder:text-shuttleGray"
              />
              {/* <div className="mt-5 flex justify-between">
                <div>
                  <span className="rounded-xl border-[1px] border-solid border-mischka p-1 text-[0.75rem]">
                    City of flower
                  </span>
                </div>
                <button className="text-medium text-xs text-link underline" type="button">
                  see text prompt examples
                </button>
              </div> */}
            </div>
          </div>
        </section>
        <section className="px-8 pt-10 pb-16">
          <div className="grid gap-y-14 md:grid-cols-2 lg:grid-cols-3">
            <div>
              <h3 className="mb-6 text-2xl font-semibold">Tier:</h3>
              <ul className="flex flex-col gap-y-5">
                <li className="flex items-center">
                  <input
                    className="peer"
                    type="radio"
                    value="free"
                    name="tier"
                    id="tier_free"
                    checked={state.tier === 'free'}
                    onChange={handleChange}
                  />
                  <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="tier_free">
                    Free Tier
                  </label>
                </li>
                <li className="flex items-center">
                  <input
                    className="peer"
                    type="radio"
                    value="premium"
                    name="tier"
                    id="tier_premium"
                    checked={state.tier === 'premium'}
                    onChange={handleChange}
                  />
                  <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="tier_premium">
                    Premium Tier{' '}
                    <span
                      className={balance < state.creditsRequired ? 'text-red' : 'text-green-500'}
                    >{`(${state.creditsRequired} CREDITS)`}</span>
                  </label>
                </li>
                {state.tier === 'premium' && (
                  // <p className="ml-8 text-link text-sm max-w-[60%]">
                  //   This is the description on this style blend. Lorem ipsum dolor sit amet.
                  // </p>
                  <div className="ml-8">
                    ☑ No branding
                    <br />
                    ☑ Background Processing
                    <br />
                    ☑ You own the Copyright
                    <br />
                    <button
                      onClick={handleBuyCredits}
                      type="button"
                      className="mt-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 text-lg font-extrabold text-dark"
                    >
                      Buy credits
                    </button>
                  </div>
                )}
              </ul>
            </div>

            <div>
              <h3 className="mb-6 text-2xl font-semibold">Art Style:</h3>
              <ul className="flex flex-col gap-y-5">
                <li className="flex items-center">
                  <input
                    type="radio"
                    value="vqganclip"
                    name="ai"
                    id="ai_vqganclip"
                    checked={state.ai === 'vqganclip'}
                    onChange={handleChange}
                  />
                  <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="ai_vqganclip">
                    Hyperion Blend
                  </label>
                </li>
                <li className="flex items-center">
                  <input
                    type="radio"
                    value="diffclip"
                    name="ai"
                    id="ai_diffclip"
                    checked={state.ai === 'diffclip'}
                    onChange={handleChange}
                  />
                  <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="ai_diffclip">
                    Cronos Blend
                  </label>
                </li>
              </ul>
            </div>

            <div>
              <h3 className="mb-6 text-2xl font-semibold">Image Resolution:</h3>
              <ul className="flex flex-col gap-y-5">
                <li className="flex items-center">
                  <input
                    type="radio"
                    value="256"
                    name="resolution"
                    id="resolution_256"
                    checked={state.resolution === '256'}
                    onChange={handleChange}
                  />
                  <label
                    className="cursor-pointer ml-2 text-lg font-medium"
                    htmlFor="resolution_256"
                  >
                    {state.lowResDetail}
                  </label>
                </li>
                <li className="flex items-center">
                  <input
                    type="radio"
                    value="512"
                    name="resolution"
                    id="resolution_512"
                    checked={state.resolution === '512'}
                    onChange={handleChange}
                  />
                  <label
                    className="cursor-pointer ml-2 text-lg font-medium"
                    htmlFor="resolution_512"
                  >
                    {state.highResDetail}
                  </label>
                </li>
                <li className="flex items-center">
                  <input
                    type="checkbox"
                    checked={state.superRes}
                    onChange={onChangeSuperRes}
                    disabled={isSubmitting}
                    id="superres"
                  />
                  <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="superres">
                    Super (4x)
                  </label>
                </li>
              </ul>
            </div>

            {state.ai !== 'diffclip' && (
              <div>
                <h3 className="mb-6 text-2xl font-semibold">Canvas Size:</h3>
                <ul className="flex flex-col gap-y-5">
                  <li className="flex items-center">
                    <input
                      type="radio"
                      value="1:1"
                      name="aspectratio"
                      id="aspectratio_1_1"
                      checked={state.aspectRatio === '1:1'}
                      onChange={handleChange}
                    />
                    <label
                      className="cursor-pointer ml-2 text-lg font-medium"
                      htmlFor="aspectratio_1_1"
                    >
                      Square (1:1)
                    </label>
                  </li>
                  <li className="flex items-center">
                    <input
                      type="radio"
                      value="16:9"
                      name="aspectratio"
                      id="aspectratio_16_9"
                      checked={state.aspectRatio === '16:9'}
                      onChange={handleChange}
                    />
                    <label
                      className="cursor-pointer ml-2 text-lg font-medium"
                      htmlFor="aspectratio_16_9"
                    >
                      Landscape (16:9)
                    </label>
                  </li>
                  <li className="flex items-center">
                    <input
                      type="radio"
                      value="9:16"
                      name="aspectratio"
                      id="aspectratio_9_16"
                      checked={state.aspectRatio === '9:16'}
                      onChange={handleChange}
                    />
                    <label
                      className="cursor-pointer ml-2 text-lg font-medium"
                      htmlFor="aspectratio_9_16"
                    >
                      Portrait (9:16)
                    </label>
                  </li>
                </ul>
              </div>
            )}

            {state.ai === 'diffclip' && (
              <div>
                <h3 className="mb-6 text-2xl font-semibold">Quality:</h3>
                <ul className="flex flex-col gap-y-5">
                  <li className="flex items-center">
                    <input
                      type="radio"
                      value="low"
                      name="diffusionquality"
                      id="diffusionquality_low"
                      checked={state.diffusionQuality === 'low'}
                      onChange={handleChange}
                    />
                    <label
                      className="cursor-pointer ml-2 text-lg font-low"
                      htmlFor="diffusionquality_low"
                    >
                      Low
                    </label>
                  </li>
                  <li className="flex items-center">
                    <input
                      type="radio"
                      value="medium"
                      name="diffusionquality"
                      id="diffusionquality_medium"
                      checked={state.diffusionQuality === 'medium'}
                      onChange={handleChange}
                    />
                    <label
                      className="cursor-pointer ml-2 text-lg font-medium"
                      htmlFor="diffusionquality_medium"
                    >
                      Medium
                    </label>
                  </li>
                  <li className="flex items-center">
                    <input
                      type="radio"
                      value="high"
                      name="diffusionquality"
                      id="diffusionquality_high"
                      checked={state.diffusionQuality === 'high'}
                      onChange={handleChange}
                    />
                    <label
                      className="cursor-pointer ml-2 text-lg font-medium"
                      htmlFor="diffusionquality_high"
                    >
                      High
                    </label>
                  </li>
                  <li className="flex items-center">
                    <input
                      type="radio"
                      value="max"
                      name="diffusionquality"
                      id="diffusionquality_max"
                      checked={state.diffusionQuality === 'max'}
                      onChange={handleChange}
                    />
                    <label
                      className="cursor-pointer ml-2 text-lg font-medium"
                      htmlFor="diffusionquality_max"
                    >
                      Max
                    </label>
                  </li>
                </ul>
              </div>
            )}

            <div>
              <h3 className="mb-6 text-2xl font-semibold">Number of Images:</h3>
              <Stepper
                min={1}
                max={100}
                value={state.multiple}
                onChange={onChangeStepper}
                render={({ getFormProps, getInputProps, getIncrementProps, getDecrementProps }) => (
                  <form {...getFormProps()}>
                    <div className="flex items-center ">
                      <button
                        className="mr-2 bg-gray-200 px-3 text-lg text-black"
                        {...getDecrementProps()}
                        type="button"
                      >
                        -
                      </button>
                      <input className="w-20 text-black" {...getInputProps()} />
                      <button
                        className="ml-2 bg-gray-200 px-3 text-lg text-black"
                        {...getIncrementProps()}
                        type="button"
                      >
                        +
                      </button>
                    </div>
                  </form>
                )}
              />
            </div>

            {state.ai !== 'diffclip' && (
              <div>
                <label className="text-2xl font-semibold">Runtime:</label>
                <div className="mt-10 ml-3">
                  <Range
                    step={100}
                    min={100}
                    max={1500}
                    values={state.iterations}
                    onChange={handleSlider}
                    renderTrack={({ props, children }) => (
                      <div {...props} className="w-full h-[1px] pr-2 my-4 bg-mischka rounded-md">
                        {children}
                      </div>
                    )}
                    renderThumb={({ props }) => (
                      <div
                        className="w-5 h-5 transform translate-x-10 bg-gradient-to-r from-spindle to-spray rounded-full focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white"
                        {...props}
                      >
                        <div className="absolute -top-7 right-[-3px] text-lg">
                          {state.iterations[0]}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="mt-16 flex justify-center">
            <button
              className="mt-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 text-lg font-extrabold text-dark"
              type="button"
              onClick={handleBlend}
              disabled={isSubmitting}
            >
              blend now
              <Spinner loading={isSubmitting} />
            </button>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default observer(CreatArt);
