/* eslint-disable class-methods-use-this */
import { makeAutoObservable } from 'mobx';
import { makePersistable, clearPersistedStore, isHydrated } from 'mobx-persist-store';
import ApiService from 'services/api/api';
import { orderBy, uniqBy } from 'lodash-es';

import type RootStore from './index';

type Filter = {
  styles?: {
    hyperion: boolean;
    crynos: boolean;
  };
  date?: {
    from: string;
    to: string;
  };
};
class ArtStore {
  rootStore: RootStore;
  stack = [];
  resume: string | null = null;
  jobs = [];
  jobDict = new Map();
  queueFilters: Filter = {
    styles: {
      hyperion: false,
      crynos: false,
    },
    date: {
      from: null,
      to: null,
    },
  };
  myArtFilters: Filter = {
    styles: {
      hyperion: false,
      crynos: false,
    },
    date: {
      from: null,
      to: null,
    },
  };

  constructor({ rootStore }) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'ArtStore',
      properties: ['stack', 'queueFilters', 'myArtFilters', 'jobDict', 'jobs'],
      storage: window.localStorage,
    });
  }

  get isHydrated(): boolean {
    return isHydrated(this);
  }

  async clearPersistedData(): Promise<void> {
    await clearPersistedStore(this);
  }

  updateJobState = (jobid: string, state: string) => {
    this.jobDict.set(jobid, state);
    if (state === 'finished') {
      const index = this.jobs.findIndex((job) => job.jobid === jobid);
      this.jobs[index].jobopen = false;
    }
  };

  *reset() {
    this.stack = [];
    this.jobs = [];
    this.resume = null;
    this.jobDict = new Map();
    return yield this.clearPersistedData();
  }

  *createArt(data: {
    ai: string;
    text: string;
    aspectRatio: string;
    resolution: string;
    iterations: number;
    creditsRequired: number;
    diffusionQuality: string;
    width: number;
    height: number;
    superRes: boolean;
    tier: string;
    multiple: number;
  }) {
    const { cognitoid } = this.rootStore.authStore;
    const aiConfig: any = {
      core: data.ai,
      user: {
        email: '', // email null results in ast parsing error in addjob lambda func
        cognitoid: String(cognitoid),
      },
      config: {
        prompt: data.text,
        width: data.width,
        height: data.height,
        superres: String(data.superRes),
        tier: data.tier,
        multiple: data.multiple,
        aspectRatio: data.aspectRatio,
        resolution: data.resolution,
        iterations: data.iterations,
        creditsRequired: data.creditsRequired,
        diffusionQuality: data.diffusionQuality,
      },
    };

    // AI core specific settings
    if (data.ai === 'diffclip') {
      aiConfig.config.quality = data.diffusionQuality;
    } else if (data.ai === 'vqganclip') {
      aiConfig.config.iterations = data.iterations;
    }

    const response = yield ApiService.instance.createArt(aiConfig);
    if (response.statusCode === 400) {
      throw new Error(response.body.error);
    }

    this.stack.push({
      jobid: response.jobid,
      queue: response.queue,
    });
    return response;
  }

  *getJobs() {
    const { cognitoid } = this.rootStore.authStore;
    const data = yield ApiService.instance.getJobs({
      cognitoid,
      resume: this.resume,
    });

    if (data.last) {
      this.resume = data.last.jobid;
    } else {
      this.resume = null;
    }

    this.jobs = uniqBy([...this.jobs, ...data.jobs], 'jobid');
    this.jobs = orderBy(this.jobs, ['timestamp'], ['desc']);
  }

  updateQueueFilters = (filters: any) => {
    this.queueFilters = filters;
  };

  updateMyArtFilters = (filters: any) => {
    this.myArtFilters = filters;
  };
}

export default ArtStore;
