/* global Paho, SigV4Utils */

import { makeAutoObservable } from 'mobx';
import AWS from 'aws-sdk';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  ICognitoUserPoolData,
  AuthenticationDetails,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import {
  makePersistable,
  clearPersistedStore,
  getPersistedStore,
  isHydrated,
  isPersisting,
} from 'mobx-persist-store';

import ApiService from 'services/api';
import { makeId, sleep } from 'utils/utils';
import type RootStore from './index';

const stackConfig = {
  up: process.env.REACT_APP_USERPOOL,
  client: process.env.REACT_APP_APPCLIENT,
  ip: process.env.REACT_APP_IDENTITYPOOL,
  poolregion: process.env.REACT_APP_POOLREGION,
};
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: stackConfig.ip,
});
const poolData: ICognitoUserPoolData = {
  UserPoolId: stackConfig.up,
  ClientId: stackConfig.client,
};
const userPool = new CognitoUserPool(poolData);

class AuthStore {
  rootStore: RootStore;
  cognitoid?: string = undefined;
  cognitoUser?: CognitoUser = undefined;
  progress = null;
  balance = null;
  isAuthenticated = false;
  tempCredentials = null;

  constructor({ rootStore }) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'AuthStore',
      properties: ['progress', 'isAuthenticated', 'balance', 'cognitoid'],
      storage: window.localStorage,
    });
  }

  get isHydrated(): boolean {
    return isHydrated(this);
  }

  get isPersisting(): boolean {
    return isPersisting(this);
  }

  async clearPersistedData(): Promise<void> {
    await clearPersistedStore(this);
  }

  *reset() {
    this.balance = 0;
    return yield this.clearPersistedData();
  }

  async getPersistedData(): Promise<void> {
    const data = await getPersistedStore(this);

    // eslint-disable-next-line no-alert
    alert(JSON.stringify(data));
  }

  *signUp({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) {
    const givenName = {
      Name: 'given_name',
      Value: firstName,
    };

    const familyName = {
      Name: 'family_name',
      Value: lastName,
    };

    const dataEmail = {
      Name: 'email',
      Value: email,
    };

    const attributeGivenName = new CognitoUserAttribute(givenName);
    const attributeFamilyName = new CognitoUserAttribute(familyName);
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeList: any = [];
    attributeList.push(attributeGivenName);
    attributeList.push(attributeFamilyName);
    attributeList.push(attributeEmail);

    const user = yield new Promise((resolve, reject) => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        return err ? reject(err) : resolve(result.user);
      });
    });
    this.cognitoUser = user;
    this.tempCredentials = {
      email,
      password,
    };
  }

  *confirmRegistration(code: string) {
    yield new Promise((resolve, reject) => {
      this.cognitoUser.confirmRegistration(code, true, (err, result) => {
        return err ? reject(err) : resolve(result);
      });
    });
    yield this.login(this.tempCredentials);
  }

  *login({ email, password }: { email: string; password: string }) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    this.tempCredentials = {
      email,
      password,
    };
    this.cognitoUser = new CognitoUser(userData);

    const data = yield new Promise((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result) {
          resolve(result);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });

    this.getAWSCredentials(data, userData.Username);
  }

  resendConfirmationCode() {
    return new Promise((resolve, reject) => {
      this.cognitoUser.resendConfirmationCode((err, result) => {
        return err ? reject(err) : resolve(result);
      });
    });
  }

  refreshCognito = () => {
    if (!this.cognitoUser) return;

    this.cognitoUser.getSession((_err, session) => {
      const refreshToken = session.getRefreshToken();
      function updateSession() {
        this.cognitoUser.refreshSession(refreshToken, (err, newSession) => {
          if (err) {
            console.log(err);
          } else {
            const token = newSession.getIdToken().getJwtToken();
            (AWS.config.credentials as any).params.Logins[
              `cognito-idp.${stackConfig.poolregion}.amazonaws.com/${stackConfig.up}`
            ] = token;
            (AWS.config as any).jwt = token;
            (AWS.config.credentials as any).refresh((error) => {
              if (error) {
                console.log(error);
              } else {
                console.log('TOKEN SUCCESSFULLY UPDATED');
              }
            });
          }
        });
      }
      updateSession();
    });
  };

  getAWSCredentials = (tokens, username) => {
    const accessToken = tokens.getIdToken().getJwtToken();

    // creates a new cognitoid
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: stackConfig.ip,
        IdentityId: this.cognitoid, // b0b0 - if not provided, a new identity is created every time
        Logins: {
          [`cognito-idp.${stackConfig.poolregion}.amazonaws.com/${stackConfig.up}`]: accessToken,
        },
      },
      {
        region: stackConfig.poolregion,
      }
    );

    this.cognitoUser.getUserAttributes((err2, attributes) => {
      if (!err2) {
        this.isAuthenticated = true;
      }
    });

    (AWS.config.credentials as any).get((err) => {
      if (err) {
        console.log(err);
      } else {
        this.updateCredentials();
      }
    });
  };

  *getBalance() {
    try {
      const data = yield ApiService.instance.getBalance(this.cognitoid);
      this.balance = data.balance;
    } catch (err) {
      console.log('getBalance', err.message);
    }
  }

  restoreUserFromStorage() {
    this.cognitoUser = userPool.getCurrentUser();
    if (this.cognitoUser) {
      return new Promise((resolve, reject) => {
        this.cognitoUser.getSession((err1, session) => {
          if (err1) {
            reject(err1);
          } else {
            console.log(`session validity: ${session.isValid()}`);
            this.getAWSCredentials(session, null);
            resolve(true);
          }
        });
      });
    }
    console.log('no currentUser');
    return Promise.resolve(null);
  }

  updateCredentials = () => {
    (AWS.config.credentials as any).get((err): void => {
      if (err) {
        this.updateCredentials();
        return;
      }

      this.cognitoid = (AWS.config.credentials as any).identityId;
      this.getBalance();
      this.handleMQTT();
    });
  };

  *handleMqttFailure(error: any) {
    console.error(`connect failed ${error.errorMessage}`);
    try {
      if (this.cognitoid) {
        yield ApiService.instance.recheckMqttAuth(this.cognitoid);
        yield sleep(1000);
        yield this.handleMQTT();
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleMQTT = () => {
    const topic = `ai/${this.cognitoid}/info`;
    const requestUrl = SigV4Utils.getSignedUrl(
      'wss',
      'a3lvh6w4b5yz3-ats.iot.us-west-2.amazonaws.com',
      '/mqtt',
      'iotdevicegateway',
      'us-west-2',
      AWS.config.credentials.accessKeyId,
      AWS.config.credentials.secretAccessKey,
      AWS.config.credentials.sessionToken
    );

    const client = new Paho.Client(requestUrl, makeId(20));
    const connectOptions = {
      onSuccess() {
        console.log('connected mqtt');
        client.subscribe(topic);
      },
      useSSL: true,
      timeout: 10,
      mqttVersion: 4,
      keepAliveInterval: 1200, // max 1200 on AWS IoT
      reconnect: true,
      onFailure: this.handleMqttFailure,
    };

    client.connect(connectOptions);
    client.onMessageArrived = (message) => {
      const msg = JSON.parse(message.payloadString);
      const status = `${msg.msg}`;
      if (status === 'status') {
        this.rootStore.artStore.updateJobState(msg.jobid, msg.state);
      }
    };
  };

  getUserAttributes() {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.getUserAttributes((err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    });
  }

  getUserData() {
    return new Promise((resolve, reject) => {
      this.cognitoUser?.getUserData((err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    });
  }

  *logout() {
    this.tempCredentials = null;
    this.isAuthenticated = false;
    yield this.rootStore.reset();

    if (this.cognitoUser) {
      this.cognitoUser.signOut();
      window.location.reload();
    }
  }

  *forgotPassword(email: string) {
    const userData = {
      Username: email,
      Pool: userPool,
    };
    this.cognitoUser = new CognitoUser(userData);
    return yield new Promise((resolve, reject) => {
      this.cognitoUser.forgotPassword({
        onSuccess(data) {
          resolve(data);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  }

  *resetPassword(verificationCode: string, newPassword: string) {
    return yield new Promise((resolve, reject) => {
      this.cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve(true);
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  }
}

export default AuthStore;
