/* eslint-disable global-require */
import { Link } from 'react-router-dom';
import { formatDistance, subDays } from 'date-fns';

import Layout from 'containers/layout';
import { ReactComponent as BtnCopyIcon } from 'assets/icons/btn-copy.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
// import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg';

const Home = () => {
  return (
    <Layout>
      <div className="absolute h-[30rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right" />
      <section className="pb-14 pt-40 md:h-[40rem] md:pt-56 md:pb-20">
        <div className="relative z-[50] flex h-full w-full items-center md:mx-auto md:max-w-7xl">
          <div className="px-8 text-center md:w-[50%] lg:w-[40%] md:text-left">
            <h1 className="text-4xl font-bold">
              NeuralBlender uses state-of-the-art AI technology to generate images from text input.
            </h1>
            <Link
              className="mt-6 inline-block w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-center text-lg font-extrabold text-dark"
              to="/create-art"
            >
              blend now
            </Link>
          </div>
        </div>
      </section>
      <div className="md:m-auto md:max-w-7xl">
        <section className="my-9 md:my-20 pb-[4.5rem]">
          <h3 className="px-8 text-2xl font-medium md:text-4xl md:font-semibold">
            Featured Community Art
          </h3>
          <div className="mt-8">
            <div className="hide-scroll-bar flex overflow-x-scroll">
              <div className="mr-8 flex flex-nowrap gap-x-12">
                {[
                  {
                    id: 1,
                    name: 'Cosmic Flower',
                    createdAt: subDays(new Date(), 3),
                    source: require('../assets/images/image-3.png'),
                  },
                  {
                    id: 2,
                    name: 'Smoking Ape',
                    createdAt: subDays(new Date(), 3),
                    source: require('../assets/images/image-2.png'),
                  },
                  {
                    id: 3,
                    name: 'Cosmic Flower',
                    createdAt: subDays(new Date(), 3),
                    source: require('../assets/images/image-1.png'),
                  },
                ].map((item, index) => (
                  <div className={`inline-block ${index === 0 ? 'ml-8' : 0} group`} key={item.id}>
                    <div className="h-[12.5rem] w-[12.5rem] overflow-hidden shadow-md transition-shadow duration-300 ease-in-out hover:shadow-xl md:relative md:h-[20rem] md:w-[20rem]">
                      <img
                        className="pointer-events-none h-[8.5rem] w-full select-none rounded-t-lg object-cover md:h-full md:rounded-xl md:group-hover:brightness-50"
                        src={item.source}
                        alt="img-art"
                      />
                      <div className="flex items-center justify-between rounded-b-lg bg-mirage px-4 pt-2 pb-4 md:absolute md:bottom-0 md:hidden md:w-full md:bg-transparent md:pb-10 md:group-hover:flex">
                        <div>
                          <p className="font-bold">{item.name}</p>
                          <div className="flex items-center text-xs text-lightGray">
                            <ClockIcon className="mr-1 inline-block h-4" />
                            <span>
                              {formatDistance(item.createdAt, new Date(), {
                                addSuffix: true,
                              })}
                            </span>
                          </div>
                        </div>
                        <BtnCopyIcon className="h-8" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* <section className="pb-[4.5rem]">
          <h3 className="px-8 text-2xl font-medium md:text-4xl md:font-semibold">Community Feed</h3>
          <div className="mt-8">
            <div className="hide-scroll-bar flex overflow-x-scroll">
              <div className="mr-8 flex flex-nowrap gap-x-12">
                {[
                  {
                    id: 1,
                    name: 'Cosmic Flower',
                    createdAt: subDays(new Date(), 3),
                    source: require('../assets/images/image-3.png'),
                    author: {
                      name: 'Ezra Stevenson',
                      avatar: 'https://i.pravatar.cc/150?img=3',
                    },
                  },
                  {
                    id: 2,
                    name: 'Smoking Ape',
                    createdAt: subDays(new Date(), 3),
                    source: require('../assets/images/image-2.png'),
                    author: {
                      name: 'Ezra Stevenson',
                      avatar: 'https://i.pravatar.cc/150?img=3',
                    },
                  },
                  {
                    id: 3,
                    name: 'Cosmic Flower',
                    createdAt: subDays(new Date(), 3),
                    source: require('../assets/images/image-1.png'),
                    author: {
                      name: 'Ezra Stevenson',
                      avatar: 'https://i.pravatar.cc/150?img=3',
                    },
                  },
                ].map((item, index) => (
                  <div
                    className={`w-[13rem] rounded-lg border-[0.5px] border-solid border-mischka py-4 px-6 ${
                      index === 0 ? 'ml-8' : 0
                    } md:w-[23rem] md:border md:border-white md:py-8 md:px-8`}
                    key={index}
                  >
                    <div className="mb-3 flex items-center justify-between">
                      <div className="flex items-center">
                        <img
                          src={item.author.avatar}
                          alt="avatar"
                          className="pointer-events-none mr-2 h-5 w-5 select-none rounded-full md:h-10 md:w-10"
                        />
                        <div>
                          <p className="text-xs font-semibold md:text-lg">{item.author.name}</p>
                          <div className="flex items-center text-tiny text-lightGray md:text-xs md:text-white">
                            <ClockIcon className="mr-1 inline-block h-2 w-2 md:hidden" />
                            <span>
                              {formatDistance(item.createdAt, new Date(), {
                                addSuffix: true,
                              })}
                            </span>
                          </div>
                        </div>
                      </div>
                      <HeartIcon className="h-5 md:hidden" />
                    </div>
                    <img
                      className="pointer-events-none h-[9rem] w-full select-none rounded-lg object-cover md:h-64"
                      src={require('../assets/images/image-2.png')}
                      alt="img-1"
                    />
                    <div className="flex justify-between pt-2 md:pt-6">
                      <div>
                        <p className="font-bold md:text-2xl">{item.name}</p>
                        <div className="text-xs md:text-sm">
                          <p>Hyperion Blend</p>
                          <p>Square 1:1</p>
                        </div>
                      </div>
                      <button type="button" className="mt-1 hidden md:inline-flex">
                        <HeartIcon className="h-8" />
                      </button>
                    </div>
                    <div className="mt-1 flex justify-end">
                      <button
                        className="text-xs font-extrabold text-link md:text-base"
                        type="button"
                      >
                        See Details
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </Layout>
  );
};

export default Home;
