/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import { formatDistance, fromUnixTime, startOfDay, endOfDay } from 'date-fns';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import FileSaver from 'file-saver';

import { getArtInfo } from 'utils/data';
import useStores from 'hooks/use-stores';
import Layout from 'containers/layout';
import Accordion from 'components/accordion';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as LayoutTwoIcon } from 'assets/icons/layout-two.svg';
import { ReactComponent as LayoutFourIcon } from 'assets/icons/layout-four.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/btn-copy.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';

import { isBetween } from 'utils/time';
import { useGlobalContext } from 'context/global-context';

const MyArt = () => {
  const {
    artStore: { getJobs, jobs, myArtFilters, updateMyArtFilters },
    authStore: { isAuthenticated },
  } = useStores();
  const [arts, setArts] = useState([]);
  const [layoutPreset, setLayoutPreset] = useState('layout-two');
  const [filterActive, setFilterActive] = useState(false);
  const [styles, setStyles] = useState(myArtFilters.styles);
  const [date, setDate] = useState(myArtFilters.date);
  const { showSignupModal } = useGlobalContext();

  useEffect(() => {
    getJobs();
  }, []);

  useEffect(() => {
    const filteredArts = jobs.filter((job) => {
      const hyperion = myArtFilters.styles?.hyperion;
      const crynos = myArtFilters.styles?.crynos;
      const createdFrom = myArtFilters.date?.from;
      const createdTo = myArtFilters.date?.to;
      const art = getArtInfo(job);

      let valid = true;
      if (createdFrom && createdTo) {
        if (art.info?.createdAt !== undefined) {
          const isBelongRange = isBetween(
            fromUnixTime(art.info.createdAt / 1000),
            startOfDay(new Date(createdFrom)),
            endOfDay(new Date(createdTo))
          );
          if (!isBelongRange) {
            valid = false;
          }
        }
      }

      if (!hyperion || !crynos) {
        if (
          (hyperion && art.job?.core !== 'vqganclip') ||
          (crynos && art.job?.core !== 'diffclip')
        ) {
          valid = false;
        }
      }

      return valid;
    });

    setArts(filteredArts);
  }, [jobs, myArtFilters]);

  const handleClearFilter = () => {
    setDate({
      from: null,
      to: null,
    });
    setStyles({
      hyperion: false,
      crynos: false,
    });
    updateMyArtFilters({
      styles: {
        hyperion: false,
        crynos: false,
      },
      date: {
        from: null,
        to: null,
      },
    });
    toast.success('Filters cleared successfully', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleToggleFilters = () => {
    if (!filterActive) {
      setFilterActive(true);
    } else {
      setStyles(myArtFilters.styles);
      setDate(myArtFilters.date);
      setFilterActive(false);
    }
  };

  const handleApplyFilter = () => {
    updateMyArtFilters({
      styles,
      date,
    });
    setFilterActive(false);
  };

  const handleDownloadArtClick = (art) => async () => {
    async function downloadImage(imageSrc: string) {
      const image = await fetch(imageSrc, { mode: 'no-cors' });
      const imageBlob = await image.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      const link = document.createElement('a');
      link.href = imageURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    try {
      await FileSaver.saveAs(
        art.info?.url,
        `${(art.info?.name || `art_${Date.now().toString()}`).replace(/\s+/g, '_')}
        .png`
      );
    } catch (e) {
      toast.error('Cannot download image. Please try again!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleShareArtClick = (art) => async () => {
    async function shareImage(imageSrc: string) {
      const image = await fetch(imageSrc, { mode: 'no-cors' });
      const imageBlob = await image.blob();
      const file = new File([imageBlob], 'fileName.png', { type: imageBlob.type });
      if (navigator.canShare) {
        if (navigator.canShare({ files: [file] })) {
          throw new Error('There is an issue with the file. Please try again later.');
        } else {
          await navigator.share({
            files: [file],
            title: 'NeuralBlender.com',
            text: '#NeuralBlender',
          });
        }
      } else {
        throw new Error('The current browser does not support share functionality');
      }
    }

    try {
      await shareImage(art.info.url);
      toast.success('Your art is shared successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e: any) {
      toast.error(e.message || 'Cannot share art now. Please try again!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleCopyArtSettings = (art) => () => {};

  const handleRefreshClick = async () => {
    try {
      await getJobs();
    } catch (e: any) {
      toast.error(e.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const renderArts = () => {
    if (arts?.length) {
      const artComponents = arts.reduce((result, job) => {
        if (!job.jobopen) {
          const art = getArtInfo(job);
          result.push(
            <div
              key={job.jobid}
              className={`group relative flex ${
                layoutPreset === 'layout-two' ? 'bg-mirage px-4 py-6' : 'md:w-[20rem]'
              }`}
            >
              <img
                className={`rounded-xl object-contain h-[9.5rem] w-full md:h-[20rem] ${
                  layoutPreset === 'layout-two' ? 'w-[9.5rem] md:w-[20rem]' : ''
                }`}
                src={art.info.url}
                alt="art"
              />

              <div
                className={`text-xs ${
                  layoutPreset === 'layout-four'
                    ? 'hidden rounded-t-xl bg-white group-hover:absolute group-hover:bottom-0 group-hover:flex group-hover:w-full group-hover:text-white group-hover:bg-dark group-hover:px-5 group-hover:py-5 group-hover:opacity-95'
                    : 'ml-5 md:flex md:flex-col md:flex-1'
                }`}
              >
                <div className="flex flex-1 flex-col">
                  <p className="text-bold text-base">{art.info.name}</p>
                  <div className="flex items-center text-lightGray">
                    <ClockIcon className="mr-1 inline-block h-3 w-3" />
                    <span>
                      {formatDistance(parseInt(art.info.createdAt, 10), new Date(), {
                        addSuffix: true,
                      })}
                    </span>
                  </div>
                  <p className="mt-3">{art.info.ai}</p>
                  <p>{art.info.canvasSize}</p>
                  <p>{art.info.resolution}</p>
                  <p>
                    {art.info.tier === 'free' ? (
                      'Free Tier'
                    ) : (
                      <>
                        Premium{' '}
                        <span className="text-link">
                          {art.info.creditsRequired ? `(${art.info.creditsRequired} CREDITS)` : ''}
                        </span>
                      </>
                    )}
                  </p>
                </div>
                <div
                  className={`hidden md:flex md:gap-x-5 ${
                    layoutPreset === 'layout-four'
                      ? 'group-hover:md:justify-between group-hover:md:flex-col-reverse group-hover:md:items-end'
                      : ''
                  }`}
                >
                  <div className="flex gap-x-5">
                    <button onClick={handleDownloadArtClick(art)}>
                      <DownloadIcon className="w-8" />
                    </button>
                    {/* <button>
                      <ShareIcon className="w-8" onClick={handleShareArtClick(art)} />
                    </button> */}
                  </div>

                  {/* <button>
                    <CopyIcon className="w-8" onClick={handleCopyArtSettings(art)} />
                  </button> */}
                </div>
              </div>
            </div>
          );
        }

        return result;
      }, []);

      if (artComponents.length) {
        return artComponents;
      }
    }

    return (
      <div className="mt-5 text-center">
        <p>There is no art now</p>
      </div>
    );
  };

  return (
    <Layout>
      <div className={filterActive ? 'hidden' : ''}>
        <div className="absolute h-[30rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right md:h-[25rem]" />
        <div className="body md:h-[35rem] md:pt-40">
          <section className="relative z-50 px-4 pb-14 pt-40 md:px-10 md:pt-20 md:pb-20">
            <div className="text-center md:w-1/2 md:text-left">
              <h1 className="mb-8 text-4xl font-bold">Let’s blend some art</h1>
              <p className="px-10 text-lg md:px-0">
                Ask the AI everything and create new sick art from text input.
              </p>
              <Link
                className="mt-10 inline-block w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-center text-lg font-extrabold text-dark"
                to="/create-art"
              >
                blend now
              </Link>
            </div>
          </section>
        </div>

        <div className="body">
          <section className="px-8 pt-8 pb-16">
            {arts.length > 2 && !isAuthenticated && (
              <div className="text-center mt-5 mb-20">
                <p className="text-center">
                  Don&apos;t lose your art. Migrate to a login-based account by signing up.
                </p>
                <button
                  className="mt-8 inline-block w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-center text-lg font-extrabold text-dark"
                  onClick={showSignupModal}
                >
                  Sign up
                </button>
              </div>
            )}
            <div className="flex justify-between">
              <div>
                <button className="mr-4" onClick={() => setLayoutPreset('layout-four')}>
                  <svg width="0" height="0">
                    <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0" />
                      <stop offset="1" />
                    </linearGradient>
                    <symbol id="border" overflow="visible">
                      <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                    </symbol>
                  </svg>
                  <div className="relative flex h-10 w-10 items-center justify-center">
                    <svg className={`module-border ${layoutPreset !== 'layout-four' && 'hidden'}`}>
                      <use href="#border" />
                    </svg>
                    <LayoutFourIcon className="h-8 w-8" />
                  </div>
                </button>
                <button className="mr-5" onClick={() => setLayoutPreset('layout-two')}>
                  <svg width="0" height="0">
                    <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                      <stop offset="0" />
                      <stop offset="1" />
                    </linearGradient>
                    <symbol id="border" overflow="visible">
                      <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                    </symbol>
                  </svg>
                  <div className="relative flex h-10 w-10 items-center justify-center">
                    <svg className={`module-border ${layoutPreset !== 'layout-two' && 'hidden'}`}>
                      <use href="#border" />
                    </svg>
                    <LayoutTwoIcon className="h-8 w-8" />
                  </div>
                </button>
                <button onClick={handleRefreshClick}>
                  <RefreshIcon />
                </button>
              </div>
              <button onClick={() => setFilterActive(true)}>
                <svg width="0" height="0">
                  <linearGradient id="grad" x1="1" x2="1" y1="0" y2="1">
                    <stop offset="0" />
                    <stop offset="1" />
                  </linearGradient>
                  <symbol id="border" overflow="visible">
                    <rect width="100%" height="100%" rx="5.3px" ry="5px" />
                  </symbol>
                </svg>
                <div className="relative flex h-10 w-10 items-center justify-center">
                  <svg className="module-border">
                    <use href="#border" />
                  </svg>
                  <FilterIcon className="h-6 w-6" />
                </div>
              </button>
            </div>
            <div
              className={`mt-10 grid gap-y-8 gap-x-6 ${
                layoutPreset === 'layout-four' ? 'grid-cols-2 md:grid-cols-3' : ''
              }`}
            >
              {renderArts()}
            </div>
          </section>
        </div>
      </div>
      <section
        className={`absolute top-0 bottom-0 left-0 right-0 z-[1000] h-[100vh] w-full overflow-hidden bg-header bg-cover bg-no-repeat px-8 pt-20 ${
          !filterActive && 'hidden'
        }`}
      >
        <button className="absolute right-5 top-10" onClick={handleToggleFilters}>
          <CloseIcon />
        </button>
        <h3 className="mb-10 text-center text-4xl font-bold">Filter</h3>
        <Accordion
          title="Date"
          content={
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              aria-hidden="true"
            >
              <DatePicker
                selected={date?.from}
                onChange={(da) => setDate((d) => ({ ...d, from: da }))}
                placeholderText="From"
                className="block w-full rounded-md px-6 py-3 bg-mischka placeholder-lightGray text-lg text-black"
              />
              <DatePicker
                selected={date?.to}
                onChange={(da) => setDate((d) => ({ ...d, to: da }))}
                placeholderText="To"
                className="mt-2 block w-full rounded-md px-6 py-3 bg-mischka placeholder-lightGray text-lg text-black"
              />
            </div>
          }
        />
        <Accordion
          title="Style"
          content={
            <ul className="flex flex-col gap-y-3 ml-1">
              <li className="flex items-center">
                <input
                  type="checkbox"
                  value="hyperion"
                  name="styles"
                  id="hyperion"
                  checked={styles.hyperion}
                  onChange={(e) => {
                    e.stopPropagation();
                    setStyles((s) => ({ ...s, hyperion: !s.hyperion }));
                  }}
                />
                <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="hyperion">
                  Hyperion
                </label>
              </li>
              <li className="flex items-center">
                <input
                  type="checkbox"
                  value="crynos"
                  name="styles"
                  id="crynos"
                  checked={styles.crynos}
                  onChange={(e) => {
                    e.stopPropagation();
                    setStyles((s) => ({ ...s, crynos: !s.crynos }));
                  }}
                />
                <label className="cursor-pointer ml-2 text-lg font-medium" htmlFor="crynos">
                  Cronos
                </label>
              </li>
            </ul>
          }
        />

        <div className="mt-20 flex items-end justify-center">
          <button
            className="mr-6 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 px-8 font-extrabold text-dark file:text-lg"
            onClick={handleApplyFilter}
          >
            apply
          </button>
          <button className="text-lg text-link underline" onClick={handleClearFilter}>
            clear all
          </button>
        </div>
      </section>
    </Layout>
  );
};

export default observer(MyArt);
