import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/main.scss';
import AWS from 'aws-sdk';
import { configure } from 'mobx';
import { configurePersistable } from 'mobx-persist-store';
import reportWebVitals from './reportWebVitals';
import App from './app';

AWS.config.update({ region: 'us-west-2' });
configure({
  // useProxies: 'never',
  // enforceActions: 'always',
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  disableErrorBoundaries: false,
});
configurePersistable({
  debugMode: false,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
