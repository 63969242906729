/* eslint-disable global-require */
/* eslint-disable react/button-has-type */
import { useImperativeHandle, forwardRef, useState, useRef, useEffect } from 'react';
import Lottie from 'lottie-react-web';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { useGlobalContext } from 'context/global-context';
import useStores from 'hooks/use-stores';
import Spinner from 'components/spinner';
import PasswordField from 'components/password-field';
import { ReactComponent as CloseSvg } from 'assets/icons/close.svg';
// import { ReactComponent as FBIcon } from 'assets/icons/btn-fb.svg';
// import { ReactComponent as TwitterIcon } from 'assets/icons/btn-twitter.svg';
// import { ReactComponent as GoogleIcon } from 'assets/icons/btn-google.svg';
// import { ReactComponent as GitIcon } from 'assets/icons/btn-git.svg';
import emailSent from 'assets/animations/envelope-mail-send.json';
import ConfirmEmail from './contents/confirm-email';
import Modal from './modal';

type LoginModalProps = {
  onSignup: (e: any) => void;
  toggleMenu: (state?: boolean) => void;
};
const LoginModal = ({ onSignup, toggleMenu }: LoginModalProps, ref) => {
  const { setLoginModalRef, hideLoginModal } = useGlobalContext();
  const {
    authStore: { login, resendConfirmationCode, forgotPassword, resetPassword },
  } = useStores();
  const [layoutType, setLayoutType] = useState('login');
  const [errorMessage, setErrorMessage] = useState('');
  const timerRef = useRef(30); // seconds
  const timerIdRef = useRef(null);
  const [startTimerResendForgotPassword, setStartTimerResendForgotPassword] = useState(false);

  useEffect(() => {
    if (startTimerResendForgotPassword) {
      timerIdRef.current = setInterval(() => {
        timerRef.current -= 1;
        if (timerIdRef.current <= 0) {
          setStartTimerResendForgotPassword(false);
          timerRef.current = 30;
        }
      }, 1000);
    }

    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };
  }, []);

  const handleLogin = async ({ email, password }) => {
    try {
      await login({ email, password });
      hideLoginModal();
      toggleMenu(false);
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        try {
          await resendConfirmationCode();
          setLayoutType('account_confirm');
        } catch (err) {
          setErrorMessage(error.message);
        }
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const handleForgotPassword = async (values) => {
    try {
      await forgotPassword(values.email);
      setLayoutType('forgot_password_email_sent');
    } catch (e) {
      toast.error(
        e.message ||
          'Oops! There is an error while sending reset password email. Please try again later!',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const formikForgotPwd = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Enter a valid email').required('Email is required'),
    }),
    onSubmit: handleForgotPassword,
  });

  const handleResetPassword = async (values, { resetForm }) => {
    try {
      await resetPassword(values.verificationCode, values.newPassword);
      setLayoutType('login');
      formikForgotPwd.resetForm();

      toast.success('Your password has been reset', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e) {
      toast.error(
        e.message || 'Oops! There is an error while reset your password. Please try again later!',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const formikLogin = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Enter a valid email').required('Email is required'),
      password: yup
        .string()
        .min(6, 'Password should be of minimum 6 characters length')
        .required('Password is required'),
    }),
    onSubmit: handleLogin,
  });

  const formikResetPwd = useFormik({
    initialValues: {
      verificationCode: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: yup.object({
      verificationCode: yup
        .string()
        .length(6, 'Verification Code must be 6 digits')
        .required('Verification Code is required'),
      newPassword: yup
        .string()
        .min(6, 'Password should be of minimum 6 characters length')
        .required('New Password is required')
        .test(
          'is-valid-password',
          'New Password must contain symbol characters',
          (value) => !!value && !!value?.match(/\W/)
        ),
      confirmPassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('newPassword')], 'Password must match'),
    }),
    onSubmit: handleResetPassword,
  });

  const handleOpen = () => {
    setLayoutType('login');
  };

  const handleClose = () => {
    formikLogin.resetForm();
    formikForgotPwd.resetForm();
    formikResetPwd.resetForm();
    setLayoutType('login');
  };

  useImperativeHandle(ref, () => ({
    show: handleOpen,
    hide: handleClose,
  }));

  const handleSignupClick = (e) => {
    onSignup(e);
    setLayoutType('login');
    formikForgotPwd.resetForm();
    formikResetPwd.resetForm();
  };

  const handleResendCode = async () => {
    try {
      if (startTimerResendForgotPassword && timerRef.current > 0) {
        throw new Error(
          `Please do send code again after ${timerRef.current} ${
            timerRef.current === 1 ? 'second' : 'seconds'
          }`
        );
      }
      setStartTimerResendForgotPassword(true);
      await forgotPassword(formikForgotPwd.values.email);
      toast.success('Reset password code is sent to your email address. Please check your email.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (e) {
      toast.error(
        e.message || 'Oops! There is an error while reset your password. Please try again later!',
        {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const renderLoginForm = () => {
    return (
      <form onSubmit={formikLogin.handleSubmit} onChange={() => setErrorMessage(null)}>
        <div className="flex flex-1 flex-col px-10">
          <h4 id="transition-modal-title" className="text-2xl font-semibold">
            Enter Neural Blender
          </h4>
          <p id="transition-modal-description" className="text-lg">
            Explore the magic of AI Art
          </p>
          {/* <div className="mt-5 mb-2 flex justify-center">
            <button>
              <GoogleIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
            <button>
              <TwitterIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
            <button>
              <FBIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
            <button>
              <GitIcon className="h-[3.5rem] w-[3.5rem]" />
            </button>
          </div>
          <span>Or</span> */}
          <div className="mt-20">
            <div className="md:w-[20rem]">
              <input
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="E-Mail"
                value={formikLogin.values.email}
                onChange={formikLogin.handleChange('email')}
                disabled={formikLogin.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formikLogin.touched.email && formikLogin.errors.email}
                </span>
              </div>
            </div>
            <div className="md:w-[20rem] mt-1 md:mt-3">
              <PasswordField
                value={formikLogin.values.password}
                onChange={formikLogin.handleChange('password')}
                disabled={formikLogin.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formikLogin.touched.password && formikLogin.errors.password}
                </span>
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                type="button"
                className="text-bold text-sm text-link underline"
                onClick={() => {
                  setLayoutType('forgot_password');
                }}
                disabled={formikLogin.isSubmitting}
              >
                Forgot password?
              </button>
            </div>
          </div>
          <div className="mt-5 mb-10 flex flex-1 flex-col items-center justify-center md:mt-10">
            <div className="h-5 mb-5 text-red">{errorMessage}</div>
            <button
              type="submit"
              className="relative w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark"
              disabled={formikLogin.isSubmitting}
            >
              login
              <Spinner loading={formikLogin.isSubmitting} />
            </button>
          </div>
          <div className="text-sm">
            <span>Do you already have an account? </span>
            <button
              className="font-bold text-link underline"
              onClick={onSignup}
              disabled={formikLogin.isSubmitting}
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderForgotPassword = () => {
    return (
      <form className="flex flex-1 flex-col px-10" onSubmit={formikForgotPwd.handleSubmit}>
        <h4 id="transition-modal-title" className="text-2xl font-bold md:text-4xl">
          Forgot your password?
        </h4>
        <p id="transition-modal-description" className="text-lg leading-5 md:px-10">
          Enter your e-mail below to receive password reset instruction
        </p>
        <div className="flex flex-col md:flex-col-reverse">
          {/* <div className="flex flex-col md:flex-col-reverse mt-5">
            <div className="mb-2 flex justify-center">
              <button>
                <GoogleIcon className="h-[3.5rem] w-[3.5rem]" />
              </button>
              <button>
                <TwitterIcon className="h-[3.5rem] w-[3.5rem]" />
              </button>
              <button>
                <FBIcon className="h-[3.5rem] w-[3.5rem]" />
              </button>
              <button>
                <GitIcon className="h-[3.5rem] w-[3.5rem]" />
              </button>
            </div>
            <span className="mb-5">Or</span>
          </div> */}

          <div className="md:mt-20 mx-auto mt-20">
            <input
              type="text"
              className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
              placeholder="E-Mail"
              value={formikForgotPwd.values.email}
              onChange={formikForgotPwd.handleChange('email')}
              disabled={formikForgotPwd.isSubmitting}
            />
            <div className="h-5 text-left">
              <span className="text-red text-xs">
                {formikForgotPwd.touched.email && formikForgotPwd.errors.email}
              </span>
            </div>
            <div className="mt-16 flex flex-1 items-center justify-center">
              <button
                className="w-36 rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark"
                type="submit"
                disabled={formikForgotPwd.isSubmitting}
              >
                send <Spinner loading={formikForgotPwd.isSubmitting} />
              </button>
            </div>
          </div>
        </div>
        <div className="h-full flex items-end text-sm justify-center">
          <div className="text-sm">
            <span>Do you already have an account? </span>
            <button
              className="font-bold text-link underline"
              onClick={handleSignupClick}
              disabled={formikForgotPwd.isSubmitting}
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderEmailForgotPasswordSent = () => {
    return (
      <div className="mt-4 flex flex-1 flex-col px-10">
        <div className="flex flex-1 flex-col items-center justify-center">
          <h4 id="transition-modal-title" className="text-2xl font-semibold">
            E-Mail has been sent!
          </h4>
          <p id="transition-modal-description" className="text-lg leading-5">
            Please check your inbox for the verification code.
          </p>
          <Lottie
            options={{
              animationData: emailSent,
              loop: false,
            }}
            height="10rem"
          />
        </div>
        <div>
          <button
            className="relative w-[13rem] rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark"
            onClick={() => setLayoutType('reset_password')}
          >
            Reset Password
          </button>
          <div className="text-sm mt-5">
            <span>Didn’t get it? </span>
            <button className="font-bold text-link underline" onClick={handleResendCode}>
              Send it again!
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderResetPasswordForm = () => {
    return (
      <form onSubmit={formikResetPwd.handleSubmit}>
        <div className="flex flex-1 flex-col px-10">
          <h4 id="transition-modal-title" className="text-2xl font-semibold">
            Reset account password
          </h4>
          <p id="transition-modal-description" className="text-lg">
            {`Enter a new password for ${formikForgotPwd.values.email}`}
          </p>
          <div className="mx-auto mt-20">
            <div className="md:w-[20rem]">
              <input
                type="text"
                className="block w-full rounded-md px-6 py-3 bg-dark placeholder-lightGray text-lg"
                placeholder="Verification Code"
                value={formikResetPwd.values.email}
                onChange={formikResetPwd.handleChange('verificationCode')}
                disabled={formikResetPwd.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formikResetPwd.touched.verificationCode &&
                    formikResetPwd.errors.verificationCode}
                </span>
              </div>
            </div>
            <div className="md:w-[20rem] mt-1 md:mt-3">
              <PasswordField
                placeholder="New Password"
                value={formikResetPwd.values.newPassword}
                onChange={formikResetPwd.handleChange('newPassword')}
                disabled={formikResetPwd.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formikResetPwd.touched.newPassword && formikResetPwd.errors.newPassword}
                </span>
              </div>
            </div>
            <div className="md:w-[20rem] mt-1 md:mt-3">
              <PasswordField
                placeholder="Confirm Password"
                value={formikResetPwd.values.confirmPassword}
                onChange={formikResetPwd.handleChange('confirmPassword')}
                disabled={formikResetPwd.isSubmitting}
              />
              <div className="h-5 text-left">
                <span className="text-red text-xs">
                  {formikResetPwd.touched.confirmPassword && formikResetPwd.errors.confirmPassword}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-10 flex flex-1 flex-col items-center justify-center md:mt-10">
            <button
              type="submit"
              className="relative w-[13rem] rounded-lg border-2 border-solid border-mischka bg-gradient-to-r from-spindle to-spray py-1 text-lg font-bold text-dark"
              disabled={formikResetPwd.isSubmitting}
            >
              Reset Password
              <Spinner loading={formikResetPwd.isSubmitting} />
            </button>
          </div>
          <div className="text-sm">
            <span>Do you already have an account? </span>
            <button
              className="font-bold text-link underline"
              onClick={onSignup}
              disabled={formikResetPwd.isSubmitting}
            >
              Sign Up
            </button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Modal ref={setLoginModalRef} onClose={handleClose}>
      <div className="relative md:max-w-5xl">
        <div className="flex min-h-[34rem] bg-dark rounded-lg border border-lightGray">
          <div className="hidden w-[40%] lg:block">
            <img
              className="h-full w-full object-cover rounded-l-lg"
              src={
                ['forgot_password', 'forgot_password_email_sent', 'reset_password'].includes(
                  layoutType
                )
                  ? require('../../assets/images/image-3.png')
                  : require('../../assets/images/image-2.png')
              }
              alt="img-1"
            />
          </div>
          <div className="flex flex-1 flex-col items-center pt-10 pb-8 text-center">
            {layoutType === 'login' && renderLoginForm()}
            {layoutType === 'forgot_password' && renderForgotPassword()}
            {layoutType === 'forgot_password_email_sent' && renderEmailForgotPasswordSent()}
            {layoutType === 'reset_password' && renderResetPasswordForm()}
            {layoutType === 'account_confirm' && (
              <ConfirmEmail
                email={formikLogin.values.email}
                onSuccess={toggleMenu}
                closeModal={handleClose}
              />
            )}
          </div>
        </div>
        <div className="absolute top-2 right-2">
          <button
            className="text-3xl leading-none outline-none focus:outline-none"
            onClick={hideLoginModal}
          >
            <span className="block bg-transparent text-white outline-none focus:outline-none">
              <CloseSvg className="h-9 w-9" />
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(forwardRef(LoginModal));
