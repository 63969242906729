import AuthStore from './auth.store';
import ArtStore from './art.store';
import PaymentStore from './payment.store';

class RootStore {
  authStore: AuthStore;
  artStore: ArtStore;
  paymentStore: PaymentStore;

  constructor() {
    this.authStore = new AuthStore({ rootStore: this });
    this.artStore = new ArtStore({ rootStore: this });
    this.paymentStore = new PaymentStore({ rootStore: this });
  }

  getStores = () => {
    return [this.authStore, this.artStore, this.paymentStore];
  };

  get isStoredSynchronized() {
    return this.getStores().every(Boolean);
  }

  reset() {
    this.getStores().forEach((store) => {
      store.reset();
    });
  }
}

export default RootStore;
