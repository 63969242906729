import { useGlobalContext, GlobalProvider } from 'context/global-context';
import LoginModal from 'components/modals/login-modal';
import { useState, ReactNode } from 'react';
import SignupModal from 'components/modals/signup-modal';
import Footer from './footer';
import NavBar from './navbar';

type LayoutProps = {
  children: ReactNode;
};
const Layout = ({ children }: LayoutProps) => {
  const { showLoginModal, hideSignupModal, showSignupModal, hideLoginModal } = useGlobalContext();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (state?: boolean) => {
    setMenuOpen((s) => (state !== undefined ? state : !s));
  };

  const handleSignupClick = (e) => {
    e.preventDefault();
    hideLoginModal();
    showSignupModal();
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    hideSignupModal();
    showLoginModal();
  };

  return (
    <>
      <div className="relative bg-dark">
        <NavBar menuOpen={menuOpen} toggleMenu={toggleMenu} />
        <div className={menuOpen ? 'hidden' : 'relative min-h-content bg-dark'}>{children}</div>
        <Footer />
      </div>
      <LoginModal onSignup={handleSignupClick} toggleMenu={toggleMenu} />
      <SignupModal onLogin={handleLoginClick} toggleMenu={toggleMenu} />
    </>
  );
};

export default Layout;
