import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import Home from 'pages/home';
import CreateArt from 'pages/create-art';
import Queue from 'pages/queue';
import Profile from 'pages/profile';
import Credits from 'pages/credits';
import FAQ from 'pages/faq';
import MyArt from 'pages/my-art';
import useStores from 'hooks/use-stores';
import Layout from 'containers/layout';
import { GlobalProvider } from 'context/global-context';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPETOKEN);

function App() {
  const rootStore = useStores();
  const [isBootstrap, setIsBootstrap] = useState(true);

  useEffect(() => {
    rootStore.authStore.restoreUserFromStorage().then(() => {
      setIsBootstrap(false);
    });
    rootStore.authStore.updateCredentials();
  }, []);

  if (!rootStore.isStoredSynchronized || isBootstrap) {
    return (
      <Layout>
        <p>Loading...</p>
        <ToastContainer />
      </Layout>
    );
  }

  return (
    <GlobalProvider>
      <Elements stripe={stripePromise}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/create-art" element={<CreateArt />} />
          <Route path="/queue" element={<Queue />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/my-art" element={<MyArt />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <ToastContainer />
      </Elements>
    </GlobalProvider>
  );
}

export default observer(App);
