/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useState } from 'react';
import StripeCheckoutForm from 'components/stripe-checkout-form';
import Layout from 'containers/layout';
import Accordion from 'components/accordion';
// import { ReactComponent as GooglePayIcon } from 'assets/icons/google-pay.svg';
// import { ReactComponent as PaypalIcon } from 'assets/icons/paypal.svg';
import { ReactComponent as StripeIcon } from 'assets/icons/stripe.svg';

const Store = () => {
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [credits, setCredits] = useState('1900');
  const paymentMethodRefs = useRef<any>({});

  const handleCreditsChange = (event) => {
    setCredits(event.target.value);
  };

  const handlePaymentMethodChange = (event: any) => {
    const { value } = event.target;
    setPaymentMethod(value);

    if (value !== 'stripe') {
      paymentMethodRefs.current.stripe.close();
    } else {
      paymentMethodRefs.current.stripe.open();
    }
  };

  return (
    <Layout>
      <div className="absolute h-[28rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right md:h-[25rem]" />
      <div className="body relative z-50">
        <section className="justify-content flex flex-col px-4 pb-5 pt-40 md:h-[35rem] md:pt-60">
          <div>
            <h1 className="mb-8 text-center text-4xl font-bold md:text-left">
              Buy <span className="text-link">CREDITS</span> to blend high quality AI ART
            </h1>
            <div className="px-10 md:px-0">
              <ul className="flex flex-col gap-y-5">
                <li className="flex items-center">
                  <input
                    type="radio"
                    value="500"
                    name="credits"
                    id="credits_500"
                    checked={credits === '500'}
                    onChange={handleCreditsChange}
                    className="h-5 w-5"
                  />
                  <label className="cursor-pointer ml-3 text-lg font-medium" htmlFor="credits_500">
                    $5 USD <span className="text-link">(100 CREDITS)</span>
                  </label>
                </li>
                <li className="flex items-center">
                  <input
                    type="radio"
                    value="1900"
                    name="credits"
                    id="credits_1900"
                    checked={credits === '1900'}
                    onChange={handleCreditsChange}
                    className="h-5 w-5"
                  />
                  <label className="cursor-pointer ml-3 text-lg font-medium" htmlFor="credits_1900">
                    $19 USD <span className="text-link">(1000 CREDITS)</span>
                  </label>
                </li>
                <li className="flex items-center">
                  <input
                    type="radio"
                    value="9900"
                    name="credits"
                    id="credits_9900"
                    checked={credits === '9900'}
                    onChange={handleCreditsChange}
                    className="h-5 w-5"
                  />
                  <label className="cursor-pointer ml-3 text-lg font-medium" htmlFor="credits_9900">
                    $99 USD <span className="text-link">(10000 CREDITS)</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="px-8 pt-10 pb-16 ">
          <h3 className="mb-6 text-2xl font-semibold">Payment method:</h3>
          <ul className="flex flex-col gap-y-5">
            <Accordion
              ref={(ref) => {
                paymentMethodRefs.current.stripe = ref;
              }}
              container="border-b-0"
              onClick={() => {
                setPaymentMethod('stripe');
                paymentMethodRefs.current.stripe.open();
              }}
              title={
                <li className="flex items-center">
                  <input
                    type="radio"
                    id="stripe"
                    value="stripe"
                    checked={paymentMethod === 'stripe'}
                    onChange={handlePaymentMethodChange}
                    className="h-5 w-5"
                  />
                  <label className="flex items-center cursor-pointer ml-3 text-lg" htmlFor="stripe">
                    <div className="w-[4.25rem]">
                      <StripeIcon className="w-[4.25rem]" />
                    </div>

                    {paymentMethod === 'stripe' && (
                      <p className="ml-3 text-xs leading-4 text-spindle text-left">
                        The payment processing is handled by{' '}
                        <span className="font-semibold">Stripe</span> and no credit card data is
                        ever sent to us.
                      </p>
                    )}
                  </label>
                </li>
              }
              content={<StripeCheckoutForm amount={credits} type="credits" />}
            />
            {/* <li className="flex items-center">
              <input
                type="radio"
                id="gpay"
                value="gpay"
                checked={paymentMethod === 'gpay'}
                onChange={handlePaymentMethodChange}
                className="h-5 w-5"
              />
              <label className="flex items-center cursor-pointer ml-3 text-lg" htmlFor="gpay">
                <div className="w-[4.25rem]">
                  <GooglePayIcon className="w-[4.25rem]" />
                </div>
                {paymentMethod === 'gpay' && (
                  <p className="ml-3 text-xs leading-4 text-spindle">
                    The payment processing is handled by{' '}
                    <span className="font-semibold">Google Pay</span> and no credit card data is
                    ever sent to us.
                  </p>
                )}
              </label>
            </li> */}
            {/* <li className="flex items-center">
              <input
                type="radio"
                id="paypal"
                value="paypal"
                checked={paymentMethod === 'paypal'}
                onChange={handlePaymentMethodChange}
                className="h-5 w-5"
              />
              <label className="flex items-center cursor-pointer ml-3 text-lg" htmlFor="paypal">
                <div className="w-[4.25rem]">
                  <PaypalIcon className="w-[4.25rem]" />
                </div>
                {paymentMethod === 'paypal' && (
                  <p className="ml-3 text-xs leading-4 text-spindle">
                    The payment processing is handled by{' '}
                    <span className="font-semibold">Paypal</span> and no credit card data is ever
                    sent to us.
                  </p>
                )}
              </label>
            </li> */}
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export default Store;
