import axios, { AxiosInstance } from 'axios';
import AwsService from 'services/aws';

class ApiService {
  // eslint-disable-next-line no-use-before-define
  private static sInstance: ApiService;
  gateway: AxiosInstance;

  constructor() {
    this.gateway = axios.create({
      timeout: 60000,
      headers: { 'Content-Type': 'application/json' },
    });

    this.gateway.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public static get instance(): ApiService {
    if (!ApiService.sInstance) {
      ApiService.sInstance = new ApiService();
    }

    return ApiService.sInstance;
  }

  async createArt(data) {
    return this.gateway.post(`${process.env.REACT_APP_STACKURL}jobs`, JSON.stringify(data));
  }

  async getJobs(data: { cognitoid: string; resume: string | null }) {
    const signedRequest: any = AwsService.instance.getSingedRequestForJob({
      stackurl: process.env.REACT_APP_STACKURL,
      ...data,
    });
    return this.gateway.request(signedRequest);
  }

  async pay(data: { cognitoid: string; type: string; amount: string; token: string }) {
    return this.gateway.post(`${process.env.REACT_APP_STACKURL}pay`, JSON.stringify(data));
  }

  async getBalance(cognitoid: string) {
    const signedRequest: any = AwsService.instance.getSingedRequestForBalance({
      stackurl: process.env.REACT_APP_STACKURL,
      cognitoid,
    });
    return this.gateway.request(signedRequest);
  }

  // eslint-disable-next-line class-methods-use-this
  async recheckMqttAuth(cognitoid: string) {
    return axios.get(process.env.REACT_APP_MQTT_AUTH, {
      params: {
        cognitoid,
      },
    });
  }
}

export default ApiService;
