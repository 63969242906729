/* eslint-disable class-methods-use-this */
import { makeAutoObservable } from 'mobx';
import { makePersistable, clearPersistedStore, isHydrated } from 'mobx-persist-store';
import ApiService from 'services/api/api';
import type RootStore from './index';

class PaymentStore {
  rootStore: RootStore;

  constructor({ rootStore }) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'PaymentStore',
      properties: [],
      storage: window.localStorage,
    });
  }

  get isHydrated(): boolean {
    return isHydrated(this);
  }

  async clearPersistedData(): Promise<void> {
    await clearPersistedStore(this);
  }

  *reset() {
    return yield this.clearPersistedData();
  }

  pay({ type, token, amount }: { type: string; token: string; amount: string }) {
    const { cognitoid } = this.rootStore.authStore;

    return ApiService.instance.pay({
      type,
      token,
      amount,
      cognitoid,
    });
  }
}

export default PaymentStore;
