/* eslint-disable react/require-default-props */
import { useRef, useState, ReactNode, useEffect, forwardRef, useImperativeHandle } from 'react';
import { ReactComponent as ArrowDownSvg } from 'assets/icons/arrow-down.svg';

interface AccordionProps {
  title: ReactNode;
  content: ReactNode;
  container?: string;
  onClick?: (event: MouseEvent) => void;
  defaultActive?: boolean;
}

const Accordion = (
  { title, content, container, onClick, defaultActive = false }: AccordionProps,
  ref
) => {
  const [active, setActive] = useState(defaultActive);
  const [height, setHeight] = useState('0px');
  const [rotate, setRotate] = useState('transform duration-700 ease');

  const contentSpace = useRef<HTMLDivElement>(null);

  const toggleAccordion = (e) => {
    setActive(!active);
    setHeight(active ? '0px' : `${contentSpace.current?.scrollHeight}px`);
    setRotate(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180');
    onClick?.(e);
  };

  useEffect(() => {
    setTimeout(() => {
      setHeight(defaultActive ? '0px' : `${contentSpace.current?.scrollHeight}px`);
      setRotate(
        defaultActive ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180'
      );
    }, 500);
  }, []);

  useImperativeHandle(ref, () => ({
    open() {
      setActive(true);
      setHeight(`${contentSpace.current?.scrollHeight}px`);
      setRotate('transform duration-700 ease rotate-180');
    },
    close() {
      setActive(false);
      setHeight('0px');
      setRotate('transform duration-700 ease');
    },
    toggle() {
      setActive((s) => !s);
      setHeight(active ? '0px' : `${contentSpace.current?.scrollHeight}px`);
      setRotate(active ? 'transform duration-700 ease' : 'transform duration-700 ease rotate-180');
    },
  }));

  return (
    <div className="flex flex-col mb-15">
      <button
        className={`bg-blackLight py-7 box-border appearance-none cursor-pointer focus:outline-none border-b border-grayLight hide-scroll-bar ${container}`}
        onClick={toggleAccordion}
      >
        <div className="flex items-center justify-between">
          <p className="inline-block font-bold text-grey text-2xl">{title}</p>
          <ArrowDownSvg className={`${rotate} inline-block`} />
        </div>
        <div
          ref={contentSpace}
          style={{ maxHeight: `${height}` }}
          className="overflow-auto transition-max-height duration-700 ease-in-out hide-scroll-bar"
        >
          <div className="pt-4 text-gray text-left mt-4">{content}</div>
        </div>
      </button>
    </div>
  );
};

export default forwardRef(Accordion);
