/* eslint-disable react/no-unescaped-entities */
import Layout from 'containers/layout';
import { ReactComponent as DiscordIcon } from 'assets/icons/discord.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail.svg';

const FAQ = () => {
  return (
    <Layout>
      <div className="absolute h-[25rem] w-full bg-header bg-cover bg-no-repeat md:mt-40 md:bg-headerBg md:bg-contain md:bg-right" />
      <div className="body">
        <div className="relative z-50 px-10 pt-40 text-center md:w-3/6 md:pt-60 md:text-left">
          <h1 className="text-5xl font-bold">FAQ</h1>
          <div className="mt-16 md:mt-8">
            <h5 className="mb-2 text-lg text-link md:text-2xl md:text-denim">
              Where can I find more information?
            </h5>
            <p>Check out our blog:</p>
            <a
              href="https://blog.neuralblender.com"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              https://blog.neuralblender.com
            </a>
            <br />
            <br />
            We also have a large Discord community with very experienced members eager to help:
            <a
              target="_blank"
              rel="noreferrer"
              className="underline"
              href="https://discord.com/invite/3eqCqN493J"
            >
              https://discord.com/invite/3eqCqN493J
            </a>
          </div>

          <div className="mt-20 md:mt-8">
            <h5 className="text-lg text-link md:text-2xl md:text-denim">
              How can I purchase credits?
            </h5>
            <p className="mt-6 leading-5">
              In the "store" tab you can purchase NeuralBlender-credits using a Credit Card. The
              payment processing is handled by Stripe and no credit card data is ever sent to us.
            </p>
          </div>
        </div>
        <div className="z-50 mt-10 mb-[8rem] grid grid-cols-1 gap-y-20 px-10 text-center md:mt-8 md:grid-cols-2 md:gap-y-0 md:gap-x-20 md:text-left">
          <div>
            <h5 className="text-lg text-link md:text-2xl md:text-denim">
              What Copyright are the images under?
            </h5>
            <p className="mt-6 leading-5">
              All free tier images are under a CC-BY License. That allows you to do pretty much
              whatever you want with them as long as you mention NeuralBlender.com. For Premium Tier
              images the full Copyright is transferred to you. This gives you total ownership and
              control over the creation. NeuralBlender.com will retain permission to host the
              created image.
            </p>
          </div>

          <div className="">
            <h4 className="text-bold text-2xl text-link md:text-denim">Contact us</h4>
            <div className="mt-5 flex justify-center md:justify-start">
              <a target="_blank" href="https://discord.gg/3eqCqN493J" rel="noreferrer">
                <DiscordIcon className="h-8 fill-mischka" />
              </a>
              <a
                className="mx-12"
                target="_blank"
                href="https://twitter.com/neuralblender"
                rel="noreferrer"
              >
                <TwitterIcon className="h-8 fill-mischka" />
              </a>
              <a href="mailto:info@neuralblender.com">
                <MailIcon className="h-8 fill-mischka" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;
